import React, { useState } from 'react'
import Layout from '../layouts/Layout'
import Form from '../components/Form'
import { LangContext } from '../utils/I18n'

const FormTemplate = data => {
  const [isCloseBanner, setCloseBanner] = useState(true);

  const { pageContext: { lang, formActive } } = data
  
  return (
    <LangContext.Provider value={lang}>
      <Layout>
        <div>
          {isCloseBanner && (
            <div className="site-down-error">
              &nbsp;&nbsp;&nbsp; <span className="site-down-text"><b>Scheduled Maintenance</b>: DRM production will be unavailable due to Hyperforce migration on Sunday, April 6, from 3:00 AM to 5:00 AM UTC. Form submissions will not be possible during this period.</span> <span className="close-icon" onClick={(e) => setCloseBanner(!isCloseBanner)}>X</span>
            </div>
          )}
          <tm-page>
            <Form formActive={formActive} />
            <div>
              <th-element-loader element="tm-page" style={{ height: '100vh' }} />
            </div>
          </tm-page>
        </div>
      </Layout>
    </LangContext.Provider>
  )
}

export default FormTemplate
